<template>
  <el-dialog
    title="訂單商品明細"
    :visible="true"
    width="700px"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <div class="member-shop-order-detail-modal">
      <p class="sub-title">使用總覽</p>
      <el-table :data="displayData" class="mb-[20px]">
        <el-table-column label="堂票編號" prop="code" align="center" width="120px" />
        <el-table-column label="未使用 / 總數" prop="serialUsed" align="center" />
        <el-table-column label="價保核銷" prop="promise" align="center" />
        <el-table-column label="作廢" prop="cancelSerialCount" align="center" />
        <!-- <el-table-column label="取消退還" align="center" /> -->
        <!-- <el-table-column label="未使用" prop="openSerialCount" align="center" /> -->
        <el-table-column label="已過期" prop="overdueSerialCount" align="center" />
      </el-table>

      <p class="sub-title">使用狀態</p>
      <div class="search-block">
        <el-input
          v-model="search.classTicketNo"
          clearable
          placeholder="堂票編號"
        />
        <el-input
          v-model="search.classTicketSerialNo"
          clearable
          placeholder="堂票序號"
        />
        <el-select v-model="search.classTicketSerialStatus" placeholder="序號狀態" clearable>
          <el-option
            v-for="item in classTicketStatusConfig"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <el-select v-model="search.promiseStatus" placeholder="價保狀態" clearable>
          <el-option
            v-for="item in promiseStatusConfig"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <el-table v-loading="loading.table" :data="displayStatusData" :tableData="tableData">
        <el-table-column label="堂票編號" fixed="left" prop="code" align="center" width="120px" />
        <el-table-column label="堂票序號" fixed="left" prop="serial" align="center" width="160px" />
        <el-table-column label="序號狀態" prop="serialStatus" align="center" width="120px">
          <template slot-scope="scope">
            <Tag :type="scope.row.serialStatus.value === 'open' ? 'action': 'info'">{{ scope.row.serialStatus.label }}</Tag>
          </template>
        </el-table-column>
        <el-table-column label="序號狀態更新時間" prop="serialUpdatedAt" align="center" width="180px" />
        <el-table-column label="價保狀態" prop="promiseStatus" align="center" width="120px">
          <template slot-scope="scope">
            <p v-if="scope.row.promiseStatus === '-'">-</p>
            <Tag v-else type="action">{{ scope.row.promiseStatus }}</Tag>
          </template>
        </el-table-column>
        <el-table-column label="價保狀態更新時間" prop="promiseUpdatedAt" align="center" width="180px" />
      </el-table>
      <!-- <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="tableDataCount"
        @pageChange="refresh(false)"
      /> -->
    </div>
  </el-dialog>
</template>

<script>
import { defineComponent, computed, reactive } from 'vue'
import { formatDate } from '@/utils/date'
import { find, get, upperCase, isNull } from 'lodash'
import { serviceClassTicketStatusConfig } from '@/config/reservation'
import { servicePromiseStatusConfig } from '@/config/classTicket'
import { useTable } from '@/use/table'
import Tag from '@/components/Tag/Tag.vue'

export default defineComponent({
  name: 'MemberShopOrderDetailClassTicketDialog',
  components: { Tag },
  props: {
    logs: { type: Array, default: () => [] },
    products: { type: Array, default: () => [] },
    productDetail: { type: Object, default: () => {} },
  },
  setup (props) {
    const { tableData, tableOptions, tableDataCount, loading, pageStartIndex } = useTable()
    const search = reactive({
      classTicketNo: '',
      classTicketSerialNo: '',
      classTicketSerialStatus: null,
      promiseStatus: null,
    })
    const classTicketStatusConfig = computed(() => {
      const config = serviceClassTicketStatusConfig
      return config
    })
    const promiseStatusConfig = computed(() => {
      const config = servicePromiseStatusConfig
      return config
    })
    const displayData = computed(() => {
      const data = get(props.productDetail, 'classTicketRecordSummaries')
      return data.map(item => {
        const code = get(item, 'code')
        const serialUsed = `${get(item, 'availableTimes') - get(item, 'openSerialCount')} / ${get(item, 'availableTimes')}`
        const promise = get(item, 'promiseCount') === 0 ? '-' : `${get(item, 'promiseUsedCount')} / ${get(item, 'promiseCount')}`
        const cancelSerialCount = get(item, 'cancelSerialCount')
        const openSerialCount = get(item, 'openSerialCount')
        const overdueSerialCount = get(item, 'overdueSerialCount')

        return {
          code,
          serialUsed,
          promise,
          cancelSerialCount,
          openSerialCount,
          overdueSerialCount,
        }
      })
    })
    const displayStatusData = computed(() => {
      let data = get(props.productDetail, 'classTicketRecordSerials')
      if (search.classTicketNo) data = data.filter(i => upperCase(renderCode(get(i, 'ClassTicketRecordId'))).includes(upperCase(search.classTicketNo)))
      if (search.classTicketSerialNo) data = data.filter(i => upperCase(get(i, 'code')).includes(upperCase(search.classTicketSerialNo)))
      if (search.classTicketSerialStatus) data = data.filter(i => get(i, 'status') === search.classTicketSerialStatus)
      if (search.promiseStatus) {
        data = data.filter(i => {
          if (search.promiseStatus === 'noPromise') {
            return get(i, 'spTicketPromiseSerial') === null
          } else return get(i, 'spTicketPromiseSerial.status') === search.promiseStatus
        })
      }
      return data.map(item => {
        const code = renderCode(get(item, 'ClassTicketRecordId'))
        const serial = get(item, 'code')
        const serialStatus = get(item, 'status') ? renderStatus(get(item, 'status')) : '-'
        const serialUpdatedAt = get(item, 'updatedAt') ? formatDate(get(item, 'updatedAt')) : '-'
        const promiseStatus = get(item, 'spTicketPromiseSerial.status') ? renderPromiseStatus(get(item, 'spTicketPromiseSerial.status')) : '-'
        const promiseUpdatedAt = get(item, 'spTicketPromiseSerial.updatedAt') ? formatDate(get(item, 'spTicketPromiseSerial.updatedAt')) : '-'

        return {
          code,
          serial,
          serialStatus,
          serialUpdatedAt,
          promiseStatus,
          promiseUpdatedAt,
        }
      })
    })
    const refresh = () => {
      console.log('refresh')
    }
    const renderCode = (classTicketRecordId) => {
      return get(find(get(props.productDetail, 'classTicketRecordSummaries'), item => item.id === classTicketRecordId), 'code')
    }
    const renderStatus = (status) => {
      return find(classTicketStatusConfig.value, item => item.value === status)
    }

    const renderPromiseStatus = (status) => {
      return get(find(promiseStatusConfig.value, item => item.value === status), 'label')
    }

    return {
      tableData,
      tableOptions,
      loading,
      tableDataCount,
      pageStartIndex,
      displayData,
      search,
      serviceClassTicketStatusConfig,
      classTicketStatusConfig,
      promiseStatusConfig,
      refresh,
      displayStatusData,
      renderCode,
      renderStatus,
      renderPromiseStatus,
      formatDate,
      get,
    }
  },
})
</script>

<style scoped lang="postcss">
.sub-title {
  @apply text-primary-100 font-bold mb-[16px]
}
.search-block {
  @apply grid grid-cols-4 w-full gap-[8px] mb-[16px]
}
::v-deep .search-block .el-input {
  @apply w-full
}
</style>
