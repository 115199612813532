<template>
  <div class="info-container-item" :style="`gap: ${data.labelGap}px`">
    <slot name="label">
      <p class="info-item-label" :style="`width: ${data.labelWidth}px`">{{ label }}</p>
    </slot>
    <slot>
      <p class="info-item-value">{{ value }}</p>
    </slot>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'InfoContainerItem',
  props: {
    label: { type: String },
    data: { type: Object, default: () => ({ labelWidth: 0, labelGap: 0 }) },
    value: { type: [String, Number] },
  },
})
</script>

<style scoped lang="postcss">
.info-container-item {
  @apply flex items-center;
}

.info-item-label {
  @apply text-normal text-gray-80;
}

.info-item-value {
  @apply text-normal text-gray-80;
  @apply font-medium;
}
</style>
