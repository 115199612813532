export const shiftColors = [
  { label: ' ', name: 'Electric Pink', code: '#fc3644' },
  { label: ' ', name: 'Red Rose', code: '#ab1438' },
  { label: ' ', name: 'Apricot', code: '#fc5c42' },
  { label: ' ', name: 'Pink Sand', code: '#feb69c' },
  { label: ' ', name: 'Turquoise', code: '#91cec2' },
  { label: ' ', name: 'Mint', code: '#a2ec8e' },
  { label: ' ', name: 'Pollen', code: '#fecb4d' },
  { label: ' ', name: 'Cream', code: '#ffe0ab' },
  { label: ' ', name: 'Blue', code: '#1ea5fc' },
  { label: ' ', name: 'Cerulian', code: '#87b9e7' },
  { label: ' ', name: 'Linen Blue', code: '#455785' },
  { label: ' ', name: 'Purple100', code: '#CE87D5' },
  { label: ' ', name: 'Purple200', code: '#8962f8' },
  { label: ' ', name: 'Lavender', code: '#a58998' },
  { label: ' ', name: 'Pine Green', code: '#00897B' },
  { label: ' ', name: 'Mist Blue', code: '#A7AB99' },
  { label: ' ', name: 'Soft White', code: '#d8c9af' },
  { label: ' ', name: 'Yellow Gold', code: '#cc996d' },
  { label: ' ', name: 'Coastal Gray', code: '#715d50' },
  { label: ' ', name: 'Gray', code: '#A2A2A2' },
]

export const pageStyles = {
  beauty: 'beauty',
  ohbot: 'ohbot',
}

export const pageStyleName = {
  [pageStyles.beauty]: '美業版',
  [pageStyles.ohbot]: '思言公版',
}

export const pageStyleList = Object.keys(pageStyleName).map(key => {
  return {
    name: pageStyleName[key],
    value: key,
  }
})

export const pageTextKeys = {
  category: 'APPOINTMENT_CATEGORY',
  service: 'APPOINTMENT_SERVICE',
  unit: 'APPOINTMENT_UNIT',
  serviceAttach: 'APPOINTMENT_SERVICE_ATTACH',
  subService: 'APPOINTMENT_SUB_SERVICE',
}

export const pageTextDefault = [
  {
    key: 'APPOINTMENT_CATEGORY',
    default: '服務類別',
  },
  {
    key: 'APPOINTMENT_SERVICE',
    default: '服務項目',
  },
  {
    key: 'APPOINTMENT_UNIT',
    default: '服務人員',
  },
  {
    key: 'APPOINTMENT_SERVICE_ATTACH',
    default: '附加服務',
  },
  {
    key: 'APPOINTMENT_SUB_SERVICE',
    default: '子項目',
  },
]

export const orderOrigins = {
  client: { label: '顧客', value: 'client' },
  admin: { label: '後台', value: 'admin' },
  openAPI: { label: '其他', value: 'openapi' },
}

export const pageTextDefaultValue = pageTextDefault.reduce((data, field) => ({ ...data, [field.key]: '' }), {})

export const tableFilterOptions = [
  { label: '電話號碼', value: 'phone' },
  { label: '訂單類型', value: 'orderType' },
  { label: '預約日期', value: 'date' },
  { label: '預約時間', value: 'time' },
  { label: '服務項目', value: 'service' },
  { label: '預約來源', value: 'origin' },
  { label: '訂單總額', value: 'totalPrice' },
  { label: '應付訂金', value: 'deposit' },
  { label: '付款方式', value: 'payment' },
]

export const orderStatusConfig = {
  cancel: { name: '已取消', value: 'cancel', tag: 'danger' },
  wait: { name: '已預約', value: 'wait', tag: 'info' },
  complete: { name: '完　成', value: 'complete', tag: 'action' },
  absence: { name: '未　到', value: 'absence', tag: 'wait' },
  confirm: { name: '確認中', value: 'confirm', tag: 'warning' },
}

export const orderHistoryEventTypeConfig = {
  confirm: { label: '訂單確認中，尚未付款', value: 'confirm' },
  paid: { label: '訂單已付款', value: 'paid' },
  paidFailed: { label: '付款失敗', value: 'paidFailed' },
  paidExpired: { label: '付款已過期', value: 'paidExpired' },
  wait: { label: '訂單已預約', value: 'wait' },
  cancel: { label: '訂單已取消', value: 'cancel' },
  complete: { label: '訂單已完成', value: 'complete' },
  absence: { label: '顧客未到', value: 'absence' },
  waitRefund: { label: '訂單等待退款中', value: 'waitRefund' },
  paidWaitRefund: { label: '訂單等待退款中', value: 'paidWaitRefund' },
  refund: { label: '訂單已完成退款', value: 'refund' },
  paidRefund: { label: '訂單已完成退款', value: 'paidRefund' },
}

export const orderHistorySourceConfig = {
  system: { label: '系統', value: 'system' },
  admin: { label: '後台', value: 'admin' },
  client: { label: '客戶', value: 'client' },
}

export const noSpecifyUnitOrderOptionConfig = {
  first: { label: '首位', value: 'first' },
  last: { label: '末位', value: 'last' },
}

export const notSpecifyRules = {
  order: { value: 'order', label: '按排序大小' },
  reservation: { value: 'reservation', label: '預約數分配' },
  notSpecifyReservation: { value: 'notSpecifyReservation', label: '不指定預約數分配' },
  random: { value: 'random', label: '隨機' },
}

export const serviceClassTicketStatusConfig = {
  open: { label: '未使用', value: 'open' },
  complete: { label: '已使用', value: 'complete' },
  overdue: { label: '已過期', value: 'overdue' },
  cancel: { label: '已取消', value: 'cancel' },
  booked: { label: '已預扣', value: 'booked' },
}

export const serviceTypeConfig = {
  humanService: { label: '純人員服務', value: 'humanService' },
  resourceService: { label: '純設備服務', value: 'resourceService' },
  humanAndResourceService: { label: '人員加設備服務', value: 'humanAndResourceService' },
}

export const notSpecifyRulesConfig = {
  order: { value: 'order', label: '按排序大小' },
  reservation: { value: 'reservation', label: '預約數分配' },
  notSpecifyReservation: {
    value: 'notSpecifyReservation',
    label: '不指定預約數分配',
  },
  random: { value: 'random', label: '隨機' },
}
