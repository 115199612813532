<template>
  <div class="info-container">
    <header>{{ title }}</header>
    <el-divider />
    <main :class="`grid-cols-${cols}`" :style="`grid-row-gap: ${rowGap}px`">
      <slot :labelWidth="labelWidth" :labelGap="labelGap" />
    </main>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'InfoContainer',
  props: {
    title: { type: String },
    cols: { type: [String, Number], default: 2 },
    rowGap: { type: [String, Number], default: 16 },
    labelWidth: { type: [String, Number] },
    labelGap: { type: [String, Number] },
  },
  setup (props) {
    return {}
  },
})
</script>

<style scoped lang="postcss">
.info-container {
  @apply bg-white;
}

.info-container header {
  @apply px-[16px] py-[14px];
  @apply text-md text-primary-100;
  @apply font-medium;
}

.info-container main {
  @apply grid px-[27px] py-[20px];
}

::v-deep .el-divider--horizontal {
@apply m-0;
}
</style>
